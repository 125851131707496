<template>
	<div
		v-if="info"
		class="grid-ghost"
		:style="computedStyles"
	>
		<OverlayPill
			v-if="info.title"
			:disable-pill-overflow="disablePillOverflow"
			:text="info.title"
		/>
	</div>
</template>

<script>
import OverlayPill from '@/components/block-grid/OverlayPill.vue';

export default {
	components: { OverlayPill },
	props: {
		info: {
			validator: (prop) => typeof prop === 'object' || prop === null,
			required: true,
		},
		disablePillOverflow: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedStyles() {
			const {
				y1,
				x1,
				y2,
				x2,
				color,
			} = this.info;

			return {
				'--position': `${y1}/${x1}/${y2}/${x2}`,
				'--outline-color': color,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.grid-ghost {
	position: relative;
	z-index: z-index(controls--grid-ghost);
	grid-area: var(--position);
	pointer-events: none;
	outline: 2px solid var(--outline-color, $accent-two);
}
</style>
