<template>
	<ZyroPill
		class="overlay-pill"
		text-class="z-body-small"
		:class="{ 'overlay-pill--no-overflow': disablePillOverflow }"
		:text-to-uppercase="false"
		v-bind="$attrs"
	/>
</template>

<script>
export default {
	props: {
		disablePillOverflow: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
.overlay-pill {
	position: absolute;
	top: -11px;
	left: 15px;
	z-index: z-index(controls--edit-block-line);
	padding: 0 8px;
	color: $light;
	background: $accent-two;

	&--no-overflow {
		top: 4px;
	}
}
</style>
